import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SidenavModule } from '../sidenav/sidenav.module';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { QuickpanelModule } from '../quickpanel/quickpanel.module';
import { RouterModule } from '@angular/router';
import { ScrollbarService } from '../scrollbar/scrollbar.service';
import { SettingsModule } from '../settings/settings.module';
import {MatListModule} from '@angular/material';
import {FooterComponent} from '../../pages/footer/footer.component';
import {DashboardLiveModule} from '../../pages/dashboard-live/dashboard-live.module';
import {DashboardLiveComponent} from '../../pages/dashboard-live/dashboard-live.component';
import {DashboardSocialsModule} from '../../pages/dashboard-socials/dashboard-socials.module';
import {DashboardSocialsComponent} from '../../pages/dashboard-socials/dashboard-socials.component';
import {AdServiceModule} from '../../pages/ad-service/ad-service.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FlexLayoutModule,
        MatSidenavModule,
        SidenavModule,
        SettingsModule,
        ToolbarModule,
        QuickpanelModule,
        MatIconModule,
        MatRippleModule,
        MatButtonModule,
        MatListModule,
        DashboardLiveModule,
        DashboardSocialsModule,
        AdServiceModule

    ],
  declarations: [LayoutComponent],
  exports: [LayoutComponent],
  providers: [ScrollbarService],
  entryComponents: [DashboardLiveComponent, DashboardSocialsComponent]
})
export class LayoutModule { }
