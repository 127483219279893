import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../global.service';
import {AuthenticationService} from '../../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SeasonsService {

  constructor(
    private httpClient: HttpClient,
    private globalService: GlobalService,
    private auth: AuthenticationService
  ) { }

  checkForUserFixedCar(event_id) {
    return this.httpClient.get(this.globalService.apiUrl + '/v2/seasons/checkFixedCar/' + event_id);
  }

  getAvailableCars(event_id, race_id = 0) {
    if (race_id > 0) {
      return this.httpClient.get(this.globalService.apiUrl + '/v2/seasons/getAvailableCars/' + event_id + '?race_id=' + race_id);
    } else {
      return this.httpClient.get(this.globalService.apiUrl + '/v2/seasons/getAvailableCars/' + event_id);
    }

  }

  getSeriesPenalties(event_id) {
    return this.httpClient.get(this.globalService.apiUrl2 + '/v2/seasons/getSeriesPenalties/' + event_id);
  }

  getSeason(date = '', eventId = '', eventindex = 0) {
    return this.httpClient.get(this.globalService.apiUrl2 + '/seasons/getSeason?date=' + date + '&event=' + eventId + '&eventindex=' + eventindex, {observe: 'events'});
  }

  getMinifiedSeason() {
    return this.httpClient.get(this.globalService.apiUrl2 + '/seasons/getMinifiedSeasonBySim');
  }

  getSeasonArchive(event_id) {
    return this.httpClient.get(this.globalService.apiUrl + '/seasons/getSeasonArchive?event_id=' + event_id, {observe: 'events'});
  }

  getFinishedSeasons() {
    return this.httpClient.get(this.globalService.apiUrl + '/seasons/getFinishedSeasons');
  }

  getRecentSessions(eventId) {
    return this.httpClient.get(this.globalService.apiUrl + '/events/' + eventId + '/getRecentSessions');
  }

  signUp(race, userdata) {

    return this.httpClient.post(this.globalService.apiUrl + '/race/' + race + '/signUp', userdata , {reportProgress: false, observe: 'events'});

  }

  changeSignUp(race, userdata) {

    return this.httpClient.post(this.globalService.apiUrl + '/race/' + race + '/changeSignUp', userdata , {reportProgress: false, observe: 'events'});

  }

  checkData(races) {
    return this.httpClient.post(this.globalService.apiUrl + '/races/checkData', {races: races, user: this.auth.currentUser } , {reportProgress: false, observe: 'events'});
  }

  getSignUpData(race_id) {
    return this.httpClient.get(this.globalService.apiUrl + '/race/' + race_id + '/getSignUpData', {reportProgress: false, observe: 'events'});
  }

  broadcastSession(race) {
    return this.httpClient.post(this.globalService.apiUrl + '/race/' + race + '/broadcast', {} , {reportProgress: false, observe: 'events'});
  }

  getSlots(race) {
    return this.httpClient.get(this.globalService.apiUrl + '/race/' + race + '/getSlots',  {reportProgress: false, observe: 'events'});
  }

  checkSignUp(race) {
    return this.httpClient.get(this.globalService.apiUrl + '/race/' + race + '/checkSignUp',  {reportProgress: false, observe: 'events'});
  }

  withdraw(race) {
    return this.httpClient.get(this.globalService.apiUrl + '/race/' + race + '/withdraw',  {reportProgress: false, observe: 'events'});
  }


  getMyUpcomingSessions(event) {
    return this.httpClient.get(this.globalService.apiUrl + '/seasons/getMyUpcomingSessions/' + event,  {reportProgress: false, observe: 'events'});
  }

  getMyPenaltyPoints(event) {
    return this.httpClient.get(this.globalService.apiUrl + '/seasons/getMyPenaltyPoints/' + event,  {reportProgress: false, observe: 'events'});
  }

  getMyPastSessions(event, start, limit) {
    return this.httpClient.get(this.globalService.apiUrl + '/seasons/getMyPastSessions/' + event + '?start=' + start + '&limit=' + limit,  {reportProgress: false, observe: 'events'});
  }

  getMySeasonWeekPoints(event) {
    return this.httpClient.get(this.globalService.apiUrl + '/seasons/getMyWeekPoints/' + event,  {reportProgress: false, observe: 'events'});
  }

  getEventStandings(event) {
    return this.httpClient.get(this.globalService.apiUrl2 + '/seasons/getSeasonEventStandings/' + event);
  }

  getEventTeamStandings(event) {
    return this.httpClient.get(this.globalService.apiUrl2 + '/seasons/getSeasonEventTeamStandings/' + event);
  }

  getEventWeekRaces(event, week) {
    return this.httpClient.get(this.globalService.apiUrl2 + '/seasons/getSeasonEventWeekRaces/' + event + '/' + week);
  }

}
