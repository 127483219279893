import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent, HttpEventType} from '@angular/common/http';
import {GlobalService} from '../../global.service';
import {observableToBeFn} from 'rxjs/internal/testing/TestScheduler';
import {add} from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  driverCategory: any = false;
  cupCategory: any = false;
  cars: any = false;
  tracks: any = false;

  constructor(
    private httpClient: HttpClient,
    private globalService: GlobalService
  ) {

    if (!this.driverCategory) {
      this.getDriverCategories();
    }

    if (!this.cupCategory) {
      this.getCupCategories();
    }

    if (!this.cars) {
      this.getCars();
    }

    if (!this.tracks) {
      this.getTracks();
    }

  }

  getDriverLaps(race_id, result_id) {
    return this.httpClient.get(this.globalService.apiUrl + "/race/" + race_id + "/getLapDetails/" + result_id);
  }

  addClassicTeam(adder) {
    return this.httpClient.post(this.globalService.apiUrl +"/v2/events/" + adder.event_id +"/addClassicTeam", adder);
  }

  getClassicTeams(event_id) {
    return this.httpClient.get(this.globalService.apiUrl +"/v2/events/" + event_id +"/getClassicTeams");
  }

  getClassicTeamsStandings(event_id) {
    return this.httpClient.get(this.globalService.apiUrl +"/v2/events/" + event_id +"/getClassicTeamsStandings");
  }

  deleteClassicTeam(event_id, id) {
    return this.httpClient.post(this.globalService.apiUrl +"/v2/events/" + event_id +"/deleteClassicTeam",id);
  }

  closeEvent(event_id) {
    return this.httpClient.get(this.globalService.apiUrl +"/v2/events/" + event_id +"/close");
  }

  deleteEvent(event_id) {
    return this.httpClient.get(this.globalService.apiUrl +"/v2/events/" + event_id +"/delete");
  }

  getCommunities() {
    return this.httpClient.get(this.globalService.apiUrl +"/communities/list");
  }

  getEventsToClone() {
    return this.httpClient.get(this.globalService.apiUrl +"/v2/events/clonelist");
  }

  getEventBroadcasters(event_id) {
    return this.httpClient.get(this.globalService.apiUrl +"/events/" + event_id + "/getBroadcasters");
  }
  addEventBroadcasters(broadcaster, event_id) {
    return this.httpClient.post(this.globalService.apiUrl +"/events/" + event_id + "/addBroadcasters", broadcaster);
  }

  getEventAdmins(event_id) {
    return this.httpClient.get(this.globalService.apiUrl +"/events/" + event_id + "/getAdmins");
  }

  addEventAdmin(broadcaster, event_id) {
    return this.httpClient.post(this.globalService.apiUrl +"/events/" + event_id + "/addAdmin", broadcaster);
  }

  deleteEventBroadcasters(broadcaster, event_id) {
    return this.httpClient.post(this.globalService.apiUrl +"/events/" + event_id + "/deleteBroadcasters", {user_id:broadcaster});
  }

  deleteEventAdmin(broadcaster, event_id) {
    return this.httpClient.post(this.globalService.apiUrl +"/events/" + event_id + "/deleteAdmin", {user_id:broadcaster});
  }

  saveGameserversForEvent(servers, event_id) {
    return this.httpClient.post(this.globalService.apiUrl +"/events/" + event_id + "/saveGameservers", servers);
  }

  confirmTeamPayment(event_id, tean_id) {
    return this.httpClient.get(this.globalService.apiUrl +"/events/" + event_id + "/setConfirmedPayment/" + tean_id);
  }

  getPositionChartData(race_id, split) {
    return this.httpClient.get( this.globalService.apiUrl + "/race/" + race_id + "/positionChart/" + split);
  }

  getConsistencyList(race_id, split) {
    return this.httpClient.get( this.globalService.apiUrl + "/race/" + race_id + "/consistency/" + split);
  }

  getBopServerData(event_id) {
    return this.httpClient.get( this.globalService.apiUrl + "/events/" + event_id + "/getBopServerData");
  }

  reactivateTeam(p_id, event_id) {
    return this.httpClient.get(this.globalService.apiUrl + "/events/" + event_id + "/reactivateTeam/" + p_id);
  }

  removeSeasonResult(race_id, split) {
    return this.httpClient.get(this.globalService.apiUrl + "/admin/race/" + race_id + "/removeSeasonRaceResult?split=" + split);
  }

  removeFromResult(race_id, result_id, split) {
    return this.httpClient.get(this.globalService.apiUrl + "/admin/race/" + race_id + "/removeFromResult?split=" + split + '&result_id=' + result_id);
  }

  recacheSeasonResult(race_id) {
    return this.httpClient.get(this.globalService.apiUrl + "/race/" + race_id + "/reparseResults");
  }

  recalculateElo(race_id, split, event_id) {
    return this.httpClient.get(this.globalService.apiUrl + "/eloForRace/" + race_id + "/" + event_id + "/" + split);
  }

  recalculateSR(race_id, split, event_id) {
    return this.httpClient.get(this.globalService.apiUrl + "/calculateSR/" + race_id + "/" + split + '?p=1fmc0d3');
  }

  reloadRaceLog(race_id, split) {
    return this.httpClient.get(this.globalService.apiUrl + "/reloadRaceLog/" + race_id + "/" + split + '?p=1fmc0d3');
  }

  download(file, race_id) {
    //return this.httpClient.get("https://api.lowfuelmotorsport.de/api/admin/race/" + race_id + "/downloadLogfile?file=" + file);
    window.open("https://api2.lowfuelmotorsport.com/api/admin/race/" + race_id + "/downloadLogfile?file=" + file);
  }

  getRaceLogFiles(raceid) {
    return this.httpClient.get(this.globalService.apiUrl + '/admin/race/' + raceid + '/getLogfiles');
  }

  blockReport(reportid) {
    return this.httpClient.get(this.globalService.apiUrl + '/admin/reko/blockreport/' + reportid);
  }

  checkBlock(reportid) {
    return this.httpClient.get(this.globalService.apiUrl + '/admin/reko/checkblock/' + reportid);
  }

  unblock(reportid) {
    return this.httpClient.get(this.globalService.apiUrl + '/admin/reko/unblock/' + reportid);
  }

  delete(reportid) {
    return this.httpClient.get(this.globalService.apiUrl + '/admin/reko/delete/' + reportid);
  }

  unlockReport(reportid) {
    return this.httpClient.get(this.globalService.apiUrl + '/admin/reko/unblock/' + reportid);
  }

  checkIfInRace(id): any {
    return this.httpClient.get(this.globalService.apiUrl + '/race/' + id + '/amIIn', {observe: 'events'});
  }

  signOutCheck(id): any {
    return this.httpClient.get(this.globalService.apiUrl + '/race/' + id + '/signOutCheck', {observe: 'events'});
  }

  checkUserMetRequirements(event_id, user_id): any {
    return this.httpClient.get(this.globalService.apiUrl + '/events/' + event_id + '/checkReq/' + user_id, {observe: 'events'});
  }

  getEvents(sim): any {

    return this.httpClient.get(this.globalService.apiUrl2 + '/events?hosted=1&com=1&sim_id=' + sim, {observe: 'events'});

  }

  getHostedEvents(com = ''): any {

    return this.httpClient.get(this.globalService.apiUrl2 + '/events?hosted=1&com=' + com, {observe: 'events'});

  }

  getHostedEventsArchive(com = ''): any {

    return this.httpClient.get(this.globalService.apiUrl2 + '/events?closed=1&hosted=1&com=' + com, {observe: 'events'});

  }

  getAllEvents(): any {
    return this.httpClient.get(this.globalService.apiUrl2 + '/events?all=1', {observe: 'events'});
  }

  getAllEventsForDashboard(): any {
    return this.httpClient.get(this.globalService.apiUrl2 + '/v2/events/running');
  }

  getTeamRaceLineUp(race_id, p_id, event_id) {
    return this.httpClient.get(this.globalService.apiUrl + '/events/' + event_id + '/getRaceLineUp/' + race_id + '/' + p_id);
  }

  getFullCustomLiveries(event_id): any {
    return this.httpClient.get(this.globalService.apiUrl + '/events/' + event_id + '/getLiveries');
  }

  getNewLiveries(event_id = false): any {
    if (event_id) {
      return this.httpClient.get(this.globalService.apiUrl + '/liveries/newliveries?event_id=' + event_id);
    } else {
      return this.httpClient.get(this.globalService.apiUrl + '/liveries/newliveries');
    }

  }

  getApprovedLiveries(name, teamname, car_model, event_id = false): any {
    if (event_id) {
      return this.httpClient.post(this.globalService.apiUrl + '/liveries/approved', {name: name, teamname: teamname, car_model: car_model, event_id: event_id});
    } else {
      return this.httpClient.post(this.globalService.apiUrl + '/liveries/approved', {name: name, teamname: teamname, car_model: car_model});
    }
  }

  getDeclinedLiveries(name, teamname, car_model, event_id = false): any {
    if (event_id) {
      return this.httpClient.post(this.globalService.apiUrl + '/liveries/declined', {name: name, teamname: teamname, car_model: car_model, event_id: event_id});
    } else {
      return this.httpClient.post(this.globalService.apiUrl + '/liveries/declined', {name: name, teamname: teamname, car_model: car_model});
    }

  }

  approveLivery(event_id, car): any {
    return this.httpClient.get(this.globalService.apiUrl + '/events/' + event_id + '/approveLivery/' + car);
  }

  approveSingleLivery(event_id, user_id): any {
    return this.httpClient.get(this.globalService.apiUrl + '/events/' + event_id + '/approveSingleLivery/' + user_id);
  }

  declineSingleLivery(event_id, user_id): any {
    return this.httpClient.get(this.globalService.apiUrl + '/events/' + event_id + '/declineSingleLivery/' + user_id);
  }

  approveSeasonsLivery(uuid): any {
    return this.httpClient.get(this.globalService.apiUrl + '/liveries/approve/' + uuid);
  }

  declineLivery(event_id, car): any {
    return this.httpClient.get(this.globalService.apiUrl + '/events/' + event_id + '/declineLivery/' + car);
  }

  declineSeasonsLivery(uuid, message): any {
    return this.httpClient.get(this.globalService.apiUrl + '/liveries/decline/' + uuid + '?message=' + message);
  }

  getEventArchive(sim): any {
    return this.httpClient.get(this.globalService.apiUrl + '/events/archive?hosted=1&com=1&sim_id=' + sim, {observe: 'events'});
  }

  setReportUnderInvestigation(report): any {
    return this.httpClient.get(this.globalService.apiUrl + '/admin/reko/underInvestigation/' + report);
  }

  getInvoiceInformation(eventId): any {
    return this.httpClient.get(this.globalService.apiUrl + '/events/' + eventId + '/getInvoiceInfo');
  }

  getEvent(id): any {

    return this.httpClient.get(this.globalService.apiUrl2 + '/events/' + id, {observe: 'events'});

  }

  getEvent3(id): any {

    return this.httpClient.get(this.globalService.apiUrl2 + '/v2/events/' + id, {observe: 'events'});

  }

  getEvent4(id): any {

    return this.httpClient.get(this.globalService.apiUrl2 + '/v2/events/' + id);

  }

  getEventForEdit(id, timezone) {

    return this.httpClient.get(this.globalService.apiUrl2 + '/v2/events-edit/' + id + '?timezone=' + escape(timezone));

  }

  deleteRaceFromEvent(id) {

    return this.httpClient.get(this.globalService.apiUrl2 + '/v2/events/delete-race/' + id);

  }

  saveEditedEvent(event, timezone, fileToUpload): any {

    if (!event.settings.sign_up_start || event.settings.sign_up_start == undefined) {
      event.settings.sign_up_start = event.signup_start;
    }

    const formData: FormData = new FormData();

    if (fileToUpload) {
      formData.append('file', fileToUpload, fileToUpload.name);
    }

    // @ts-ignore
    this.buildFormData(formData, event);

    return this.httpClient.post(this.globalService.apiUrl + '/v2/events-edit/' + event.event_id + '/save?timezone=' + escape(timezone), formData);

  }

  getEventForClone(event_id, timezone) {
    return this.httpClient.get(this.globalService.apiUrl2 + '/v2/events-edit/clone/' + event_id + '?timezone=' + escape(timezone));
  }

  buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : (data == "true" ? true : (data == "false" ? false : data));

      formData.append(parentKey, value);
    }
  }

  getEventRaces(id): any {

    return this.httpClient.get(this.globalService.apiUrl2 + '/v2/events/' + id + '/races');

  }

  getEvent2(id): any {

    return this.httpClient.get(this.globalService.apiUrl + '/events/' + id + '?noRaces=true');

  }

  saveEvent(event): any {

    return this.httpClient.post(this.globalService.apiUrl + '/events/' + event.event_id + '/save', {event: event});

  }



  checkIfInEvent(id): any {
    return this.httpClient.get(this.globalService.apiUrl + '/events/' + id + '/checkRegistration', {observe: 'events'});
  }

  getTeamSignUp(event_id, team_id) {
    return this.httpClient.get(this.globalService.apiUrl + '/events/' + event_id + '/getRegistration/' + team_id, {observe: 'events'});
  }

  getEventLight(id): any {

    return this.httpClient.get(this.globalService.apiUrl + '/events/' + id + '?noRaces=true', {observe: 'events'});

  }

  getRace(id): any {
    return this.httpClient.get(this.globalService.apiUrl2 + '/race/' + id , {observe: 'events'});
  }

  getRaceMinified(id): any {
    return this.httpClient.get(this.globalService.apiUrl2 + '/raceMinified/' + id , {observe: 'events'});
  }

  getQualifyingResults(id): any {
    return this.httpClient.get(this.globalService.apiUrl2 + '/race/' + id + '/qualifyingResults' , {observe: 'events'});
  }

  getQualifyingResultsBySession(id, session): any {
    return this.httpClient.get(this.globalService.apiUrl2 + '/race/' + id + '/qualifyingResults?session=' + session , {observe: 'events'});
  }

  getEventAdminStuff(id, split): any {

    return this.httpClient.get(this.globalService.apiUrl + '/events/' + id + '/adminSettings?split=' + split, {observe: 'events'});

  }

  getServerSettings(id): any {
    return this.httpClient.get(this.globalService.apiUrl + '/race/' + id + '/serverSettings');
  }

  getQualifyingServerSettings(id): any {
    return this.httpClient.get(this.globalService.apiUrl + '/race/' + id + '/serverSettings?type=quali');
  }

  getEventLiveReports(id): any {

    return this.httpClient.get(this.globalService.apiUrl + '/admin/reko/getRekoLiveReports/' + id );

  }

  getEventLiveReportHistory(id): any {

    return this.httpClient.get(this.globalService.apiUrl + '/admin/reko/getEventLiveReportHistory/' + id );

  }

  getPrequaliLeaderboard(id): any {

    return this.httpClient.get(this.globalService.apiUrl2 + '/events/' + id + '/preQualiLeaderboard', {observe: 'events'});

  }

  setDriverInactive(driver, event) {
    return this.httpClient.post(this.globalService.apiUrl + '/events/' + event + '/setDriverInactive', {driver: driver}, {reportProgress: false, observe: 'events'});
  }

  saveJudgement(report) {
    return this.httpClient.post(this.globalService.apiUrl + '/admin/reko/judge', {report: report}, {reportProgress: false, observe: 'events'});
  }

  saveChatReportJudgement(report) {
    return this.httpClient.post(this.globalService.apiUrl + '/admin/reko/saveChatReport', {report: report});
  }

  checkOtherReports(reportid) {
    return this.httpClient.get(this.globalService.apiUrl + '/admin/reko/checkForOtherReports/' + reportid);
  }

  holdReport(reportid, note) {
    return this.httpClient.post(this.globalService.apiUrl + '/admin/reko/holdReport/' + reportid, {note: note}, {reportProgress: false, observe: 'events'});
  }

  returnReportToUser(report_id, reason) {
    return this.httpClient.post(this.globalService.apiUrl + '/admin/reko/returnToUser/' + report_id, {reason: reason});
  }

  reopenReport(reportId) {
    return this.httpClient.post(this.globalService.apiUrl + '/admin/reko/reopen', {report: reportId}, {reportProgress: false, observe: 'events'});
  }

  unBan(data) {
    return this.httpClient.post(this.globalService.apiUrl + '/admin/reko/unban', {report: data.report_id, user_id: data.id, penalty: data.penalty_id}, {reportProgress: false, observe: 'events'});
  }

  parseLogFile(params, id): any {

    return this.httpClient.post(this.globalService.apiUrl + '/events/' + id + '/parseLogs', params, {reportProgress: false, observe: 'events'});

  }

  getDriverEntryFromSteamId(steamId, eventId): any {

    return this.httpClient.post(this.globalService.apiUrl2 + '/events/' + eventId + '/getEntryBySteamId', {steam_id: steamId}, {reportProgress: false, observe: 'events'});

  }

  saveServerSettings(raceId, settings, type): any {
    if (type) {
      return this.httpClient.post(this.globalService.apiUrl + '/race/' + raceId + '/saveServerSettings?type=' + type, {settings}, {reportProgress: false, observe: 'events'});
    } else {
      return this.httpClient.post(this.globalService.apiUrl + '/race/' + raceId + '/saveServerSettings', {settings}, {reportProgress: false, observe: 'events'});
    }


  }

  uploadServerSettings(raceId, split = 1): any {
    return this.httpClient.post(this.globalService.apiUrl + '/race/' + raceId + '/uploadToServer?split=' + split, {raceId}, {reportProgress: false, observe: 'events'});
  }

  uploadServerSettingsQuali(raceId): any {
    return this.httpClient.post(this.globalService.apiUrl + '/race/' + raceId + '/uploadToServer', {qualifying: true}, {reportProgress: false, observe: 'events'});
  }

  getDriverCategories(): any {

    this.httpClient.get(this.globalService.apiUrl2 + '/lists/getDriverCategories', {observe: 'events'}).subscribe(
      ( response: HttpEvent<any> ) => {
        switch (response.type) {
          case HttpEventType.Response:
            this.driverCategory = response.body;
            ////console.log(this.driverCategory);
            break;
        }
      });

  }

  getCupCategories(): any {

    this.httpClient.get(this.globalService.apiUrl2 + '/lists/getCupCategories', {observe: 'events'}).subscribe(
      ( response: HttpEvent<any> ) => {
        switch (response.type) {
          case HttpEventType.Response:
            this.cupCategory = response.body;
            ////console.log(this.cupCategory);
            break;
        }
      });

  }

  getCars(): any {

    this.httpClient.get(this.globalService.apiUrl2 + '/lists/getCars', {observe: 'events'}).subscribe(
      ( response: HttpEvent<any> ) => {
        switch (response.type) {
          case HttpEventType.Response:
            this.cars = response.body;
            ////console.log(this.cars);

            response.body.forEach( (car: any) => {
              if (!this.globalService.carmapping[car.car_id] && car.thumbnail != '') {
                this.globalService.carmapping[car.car_id] = car.thumbnail;
              }
            });

            break;
        }
      });

  }

  getTracks(): any {

    this.httpClient.get(this.globalService.apiUrl2 + '/lists/getTracks', {observe: 'events'}).subscribe(
      ( response: HttpEvent<any> ) => {
        switch (response.type) {
          case HttpEventType.Response:
            this.tracks = response.body;
            ////console.log(this.tracks);
            break;
        }
      });

  }

  signoutFromRace(eventid, raceid): any {
    return this.httpClient.post(this.globalService.apiUrl + '/events/' + eventid + '/signoutFromRace/' + raceid, {}, {reportProgress: false, observe: 'events'});
  }

  removesignoutFromRace(eventid, raceid): any {
    return this.httpClient.post(this.globalService.apiUrl + '/events/' + eventid + '/removesignoutFromRace/' + raceid, {}, {reportProgress: false, observe: 'events'});
  }

  updateDriverEntry(data) {

    return this.httpClient.post(this.globalService.apiUrl + '/events/' + data.event_id + '/updateDriverEntry', {data}, {reportProgress: false, observe: 'events'});


  }

  saveBroadcastingSettings(data) {

    return this.httpClient.post(this.globalService.apiUrl + '/events/' + data.event_id + '/updateBroadcastingSettings', data.broadcasting_settings, {reportProgress: false, observe: 'events'});

  }

  registerDriver(driver) {

    return this.httpClient.post(this.globalService.apiUrl + '/events/' + driver.event_id + '/register', {driver: driver}, {reportProgress: false, observe: 'events'});

  }

  checkBookingPw(pw, event_id) {
    return this.httpClient.post(this.globalService.apiUrl + '/events/' + event_id + '/checkBookingPw', {password: pw}, {reportProgress: false, observe: 'events'});
  }

  getReportsForReko(limit = 0) {

    return this.httpClient.get(this.globalService.apiUrl + '/admin/reko/getRekoReports?limit=' + limit, {reportProgress: false, observe: 'events'});

  }

  getPendingSelfReportsForReko() {

    return this.httpClient.get(this.globalService.apiUrl + '/admin/reko/getPendingSelfReports', {reportProgress: false, observe: 'events'});

  }

  getHoldReports() {

    return this.httpClient.get(this.globalService.apiUrl + '/admin/reko/getHoldReports', {reportProgress: false, observe: 'events'});

  }

  getFinishedReportsForReko() {
    return this.httpClient.get(this.globalService.apiUrl + '/admin/reko/getFinishedRekoReports', {reportProgress: false, observe: 'events'});
  }

  getActiveBans() {
    return this.httpClient.get(this.globalService.apiUrl + '/admin/reko/getActiveBans', {reportProgress: false, observe: 'events'});
  }

  getRekoUsers() {
    return this.httpClient.get(this.globalService.apiUrl + '/admin/reko/rekoUsers');
  }

  deleteUserFromReko(id) {
    return this.httpClient.post(this.globalService.apiUrl + '/admin/reko/removeUserFromReko', {user_id: id});
  }

  deleteUserFromRekoEvent(id, event) {
    return this.httpClient.post(this.globalService.apiUrl + '/admin/reko/removeUserFromReko', {user_id: id, 'event': event});
  }

  addUserToRekoEvent(id, event) {
    return this.httpClient.post(this.globalService.apiUrl + '/admin/reko/addUserToRekoEvents', {user_id: id, 'event': event});
  }

  searchFinishedReportsForReko(id) {
    return this.httpClient.get(this.globalService.apiUrl + '/admin/reko/getFinishedRekoReports?search=' + id, {reportProgress: false, observe: 'events'});
  }

  checkTwitch(channels) {

    return this.httpClient.get(this.globalService.apiUrl2 + '/checkTwitch/' + channels.join(), {reportProgress: false, observe: 'events'});

  }

  checkBroadcaster() {

    return this.httpClient.get(this.globalService.apiUrl2 + '/checkBroadcaster', {reportProgress: false, observe: 'events'});

  }

  checkYouTubeBroadcaster() {
    return this.httpClient.get(this.globalService.apiUrl2 + '/checkYoutubeBroadcasts');
  }

  startServer(server) {

    return this.httpClient.get(this.globalService.apiUrl + '/server/start/' + server, {reportProgress: false, observe: 'events'});

  }

  stopServer(server) {
    return this.httpClient.get(this.globalService.apiUrl + '/server/stop/' + server, {reportProgress: false, observe: 'events'});
  }

  getServerStatus(server) {
    return this.httpClient.get(this.globalService.apiUrl + '/server/status/' + server, {reportProgress: false, observe: 'events'});
  }

  getUpcomingBroadcasts() {
    return this.httpClient.get(this.globalService.apiUrl2 + '/dashboard/upcomingBroadcasts', {reportProgress: false, observe: 'events'});
  }

  setActiveOverlay(overlay) {
    console.log(overlay);
    this.httpClient.post(this.globalService.apiUrl + '/broadcasting/setActiveOverlay', {'overlay' : overlay}).subscribe((data:any) => {
      console.log("SAVED " + overlay);
    });

  }

  getSeasonRaceParticipants(split, event) {
    return this.httpClient.get(this.globalService.apiUrl2 + '/seasons/getActiveSeasonRaceParticipants?split=' + split + '&event=' + event);
  }

  getRaceParticipants(split, event) {
    return this.httpClient.get(this.globalService.apiUrl2 + '/events/getActiveRaceParticipants?split=' + split + '&event=' + event);
  }

  getRf2ServerCarList(event_id) {
    return this.httpClient.get(this.globalService.apiUrl + '/events/' + event_id + '/getRf2EventCarList');
  }

  getTrackData(track_id) {
    return this.httpClient.get(this.globalService.apiUrl + '/lists/trackData/' + track_id);
  }

  saveTimePenalty(data) {
    return this.httpClient.post(this.globalService.apiUrl + '/events/' + data.event_id + '/saveTimePenalty', data);
  }

  dqDriver(data) {
    return this.httpClient.post(this.globalService.apiUrl + '/events/' + data.event_id + '/dqDriver', {result_id: data.result_id});
  }

  getCommunityGameservers(id) {
    return this.httpClient.get(this.globalService.apiUrl + '/server/community/' + id);
  }

}
